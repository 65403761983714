<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <h5 class="h5-text mb-8">Запрос на поиск транспортного средства<span v-if="requestDetails.archived"> [архив]</span></h5>
      <v-spacer></v-spacer>
      <v-btn color="grey" outlined @click="archiveDialog = true" v-if="!requestDetails.archived">
        <v-icon class="mr-2">mdi-archive-arrow-down-outline</v-icon> В архив
      </v-btn>
      <v-btn color="primary" outlined @click="fromArchiveDialog = true" v-if="requestDetails.archived">
        <v-icon class="mr-2">mdi-archive-arrow-down-outline</v-icon> Восстановить
      </v-btn>
    </div>
    <div class="page-block flex-column mb-6">
      <div class="subhead-text mb-8">Запрос №{{ requestDetails.id }} от {{ formatDate(requestDetails.date) }}</div>
      <v-divider class="my-4"></v-divider>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <div class="subhead-text mb-8">Инициатор</div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Лизинговая компания </div>
            <div class="body-l-text"> {{ requestDetails.leasingCompany }} </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Инициатор </div>
            <div class="body-l-text"> {{ requestDetails.manager.last_name }} {{ requestDetails.manager.first_name }} </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Телефон </div>
            <div class="body-l-text"> {{ requestDetails.manager.phone }} </div>
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <div class="subhead-text mb-8">Информация о транспорте</div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Марка авто </div>
            <div class="body-l-text"> {{ requestDetails.carBrand }} </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Модель авто </div>
            <div class="body-l-text"> {{ requestDetails.carModel }} </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Город </div>
            <div class="body-l-text"> {{ requestDetails.city }} </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <div class="caption-text mb-1">Комплектация</div>
          <div class="body-l-text"> {{ requestDetails.equipment }} </div>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <div class="caption-text mb-1">Бюджет на авто, до</div>
          <div class="body-l-text"> {{ translateCarPrice(requestDetails.budget) }} </div>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <div class="caption-text mb-1">Имя клиента или ID в вашей CRM</div>
          <div class="body-l-text"> {{ requestDetails.client }} </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <div class="caption-text mb-1">Тип</div>
          <div class="body-l-text"> {{ requestDetails.is_truck ? 'Грузовой' : 'Легковой' }} </div>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <div class="caption-text mb-1">Состояние</div>
          <div class="body-l-text"> {{ requestDetails.with_mileage ? 'С пробегом' : 'Новый' }} </div>
        </v-col>
        <v-col cols="12" sm="6" md="2" v-if="requestDetails.with_mileage">
          <div class="caption-text mb-1">Год выпуска, до</div>
          <div class="body-l-text"> {{ requestDetails.year }} </div>
        </v-col>
        <v-col cols="12" sm="6" md="2" v-if="requestDetails.with_mileage">
          <div class="caption-text mb-1">Пробег (км.), до</div>
          <div class="body-l-text"> {{ translateMileage(requestDetails.mileage) }} </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <div class="table-card__item mb-8">
            <div class="caption-text mb-2"> Комментарий
              <v-btn @click="editCommentDialog = true" text small color="blue" class="font-12">Редактировать</v-btn>
            </div>
            <div class="body-l-text"> {{ requestDetails.comment }} </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <h5 class="h5-text mb-2">Добавить новый КП</h5>
    <div
      class="d-flex flex-column mb-12"
    >
      <new-response-from-admin-form :archived="requestDetails.archived"></new-response-from-admin-form>
    </div>
    <h5 class="h5-text mb-2">Ответы</h5>
    <div
      v-if="dealershipResponses.length > 0"
      class="d-flex flex-column mb-12"
    >
      <dealership-response
        v-for="dealershipResponse in dealershipResponses"
        :key="dealershipResponse.id"
        :dealership-response="dealershipResponse"
        :request-id="requestDetails.id"
        :archived="requestDetails.archived"
        :is-show-rezerv-modal.sync="showModalRez"
        :id-offer-dealer.sync="idOffer"
        :type-modal.sync="typeModal"
      ></dealership-response>
    </div>
    <v-dialog v-model="archiveDialog" max-width="350">
      <ArchiveDialog
        user-name=""
        :request-id="requestDetails.id"
        @close="archiveDialog = false"
        :dsManagersIds="dsManagersIds"
      ></ArchiveDialog>
    </v-dialog>
    <v-dialog v-model="fromArchiveDialog" max-width="350">
      <FromArchiveDialog
        user-name=""
        :request-id="requestDetails.id"
        @close="fromArchiveDialog = false"
        :dsManagersIds="dsManagersIds"
      ></FromArchiveDialog>
    </v-dialog>
    <v-dialog v-model="editCommentDialog" max-width="400">
      <EditCommentDialog
        :comment="requestDetails.comment"
        :request-id="requestDetails.id"
        @close="editCommentDialog = false"
        @submit="commentUpdated"
      ></EditCommentDialog>
    </v-dialog>
    <rezerv-lc-modal
        :is-show-modal.sync="showModalRez"
        :id-offer-modal.sync="idOffer"
        :need-update-dealer.sync="needUpdate"
        :type-modal.sync="typeModal"
    >
    </rezerv-lc-modal>
  </div>
</template>

<script>
// import Component from 'vue-class-component'
// import { Mixins } from 'vue-property-decorator'
// import Vue from 'vue'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import moment from 'moment'
import { Http } from '@/apis/Http'
// import { Watch } from 'vue-property-decorator'
import CarSearchRequest from '@/apis/CarSearchRequest'
import DealershipResponse
  from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/DealershipResponse.vue'
import { objectUtils } from '@/utils/object'
import _ from 'lodash'
import NewResponseFromAdminForm from '@/views/Admin/CarSearchRequests/components/NewResponseFromAdmintForm.vue'
import ArchiveDialog from '@/views/SearchLeasObject/components/ArchiveDialog.vue'
import FromArchiveDialog from '@/views/SearchLeasObject/components/FromArchiveDialog.vue'
import EditCommentDialog from './components/EditCommentDialog'
import RezervLcModal from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/RezervLсModal.vue'

export default {
  mixins: [TableMixin],
  components: {
    DealershipResponse,
    NewResponseFromAdminForm,
    ArchiveDialog,
    FromArchiveDialog,
    EditCommentDialog,
    RezervLcModal
  },
  data() {
    return {
      simpleTable: true,

      showModalRez: false,
      idOffer: null,
      typeModal: 1,
      needUpdate: false,

      requestDetails: {
        carBrand: null,
        carModel: null,
        city: null,
        comment: null,
        date: null,
        leasingCompany: null,
        manager: {
          first_name: null,
          last_name: null,
          phone: null
        }
      },

      dealershipResponses: [],
      dsManagersIds: [],
      archiveDialog: false,
      fromArchiveDialog: false,
      editCommentDialog: false

    }
  },

  watch: {
    needUpdate: function (val) {
      if (val) {
        Http.get('/search-leas-object/get-details', {
          params: {
            id: this.$route.params.id
          }
        })
        .then(response => {
          this.dealershipResponses = this.sortDsByPrices(response.data.dealershipResponses)
          this.leasObjectDetails = response.data.leasObjectDetails
          this.needUpdate = false
          this.getKpChatCount()
        })
        .catch(() => {
          // this.showErrorSystemNotification('Что-то пошло не так!')
        })
        .finally(() => {
          this.$setLoading(false)
        })
      }
    }
  },

  created() {
    this.fetchCarSearchRequest(this.$router.currentRoute.params.id)
  },

  methods: {
    fetchCarSearchRequest(id) {
      CarSearchRequest.fetchOne(id)
        .then(({ data }) => {
          this.dealershipResponses = this.sortDsByPrices(data.dealershipResponses)
          data.request.archived = !!data.request.archived
          this.requestDetails = data.request
          for (const dealershipRes of this.dealershipResponses) {
            for (const item of dealershipRes.items) {
              if (!this.dsManagersIds.includes(item.user.id)) this.dsManagersIds.push(item.user.id)
            }
          }
        })
    },

    commentUpdated(comment) {
      this.requestDetails.comment = comment
      this.editCommentDialog = false
    },

    sortDsByPrices(data) {
      data.forEach(function (item) {
        return objectUtils.sortByPrice(item.items)
      })
      return data.sort((a, b) => (this.maxPrice(a.items) > this.maxPrice(b.items))
        ? 1 : ((this.maxPrice(b.items) > this.maxPrice(a.items)) ? -1 : 0))
    },

    maxPrice(items) {
      const obj = _.minBy(items, 'carPrice')
      return (obj !== undefined) ? obj.carPrice : Infinity
    },

    formatDate(date) {
      return moment(date).format('LLL')
    },

    countOrders(number) {
      return stringUtils.pluralForm(number, ['рубль', 'рубля', 'рублей'])
    },

    translateCarPrice(price) {
      return price ? stringUtils.priceDisplay(price) : ''
    },

    translateBudget(price) {
      return price ? stringUtils.priceDisplay(price) : ''
    },

    translateMileage(price) {
      return price ? stringUtils.priceDisplay(price, ' км') : ''
    }
  }
}
</script>
<style>
  .font-12 .v-btn__content {
    font-size: 12px;
  }
</style>
