<template>
  <v-card>
    <v-card-title class="chat-black-101">
      Редактировать комментарий
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-textarea outlined v-model="newComment"></v-textarea>
      </div>
    </v-card-text>
    <v-card-actions class="d-block">
      <div class="mb-3"><v-btn large block color="primary" @click="submit()" outlined>Сохранить</v-btn></div>
      <div class="mb-5"><v-btn block large color="error" @click="$emit('close')" outlined>Отмена</v-btn></div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Http } from '../../../../apis/Http'

export default {
  props: ['comment', 'requestId'],

  data: () => ({
    newComment: ''
  }),

  mounted() {
    this.newComment = this.comment
  },

  methods: {
    submit() {
      Http.post('/admin/car-search-requests/update-comment', { id: this.requestId, comment: this.newComment })
      this.$emit('submit', this.newComment)
    }
  }
}
</script>
