<template>
  <div>
    <v-card>
      <v-card-title class="chat-black-101">
        {{ $store.state.auth.user.first_name }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          Возможно, Вы сможете предложить клиенту альтернативный вариант комплектации автомобиля или другой аналогичный автомобиль.
        </div>
      </v-card-text>
      <v-card-actions class="d-block pb-0">
        <div class="mb-3"><v-btn large block color="primary" @click="$emit('close')" outlined>Добавить альтернативное предложение</v-btn></div>
        <div class="mb-5">
          <v-btn block large color="error" @click="$emit('submit')" outlined>
            <v-icon class="mr-3">mdi-car-off</v-icon> Завершить. Авто нет в наличии
          </v-btn>
        </div>
      </v-card-actions>
      <v-card-text class="pt-0">
        Вы также можете указать, когда автомобиль будет в поставке, чтобы лизинговая компания на него могла рассчитывать
        <div class="mt-3">
          <v-btn color="primary">
            {{ form.year }}
          </v-btn>
        </div>
        <div class="mt-3">
            <v-btn
              v-for="item in months"
              :key="item.index"
              :outlined="item.index != form.month"
              class="mr-2 mb-2"
              :color="item.index == form.month ? 'primary' : ''"
              @click="setMonth(item)"
            >
              {{ item.name }}
            </v-btn>
        </div>
        <v-alert
          dense
          outlined
          type="error"
          v-if="error"
        >
          Выберите месяц поставки
        </v-alert>
        <div class="mt-2">
          Комментарий
          <v-textarea
            v-model="form.comment"
            outlined
            dense
            rows="2"
            hide-details
          ></v-textarea>
        </div>
        <div class="mt-3">
          <v-btn large block color="primary" @click="submitOffer()" outlined>Отправить</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        form: {
          year: moment().format('YYYY'),
          month: 0,
          monthName: '',
          comment: ''
        },
        months: [],
        error: false
      }
    },

    created() {
      const currentMonth = parseInt(moment().format('M')) > 0 ? parseInt(moment().format('M')) - 1 : 0
      this.months = Array.from(moment.months(), (name, index) => { return { name, index: index + 1 } }).slice(currentMonth)
    },

    methods: {

      setMonth(item) {
        this.form.month = item.index
        this.form.monthName = item.name
        this.error = false
      },

      submitOffer() {
        if (!this.form.month) {
          this.error = true
          return
        }
        this.$emit('submitNoCarOffer', this.form)
      }
    }
  }
</script>
<style>
  .choose-month button {
    border-left-width: 1px !important;
    border-radius: 4px !important;
  }
</style>
