<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="d-flex flex-column justify-space-between w-100"
  >
    <v-row v-if="dealerships.length > 1">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="ml-0"
      >
        <v-select
          v-model="form.dealershipId"
          :items="dealerships"
          item-value="id"
          item-text="name"
          outlined
          placeholder="Выберите автосалон"
          label="Автосалон"
          :disabled="dealerships.length === 1 || archived"
          :rules="[v => !!v || 'Выберите автосалон']"
        ></v-select>
      </v-col>
    </v-row>
    <div v-for="(model, index) in kps" :key="index">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        order="1"
      >
        <v-file-input
          v-model="kps[index].file"
          placeholder="Добавить файл"
          label="Спецификация"
          prepend-inner-icon="mdi-file-outline"
          prepend-icon=""
          outlined
          :rules="fileRules"
          :disabled="archived"
          data-click-clear="clearFile(index)"
        >
          <template v-slot:selection="{ text }">
            <v-chip
              small
              label
              color="primary"
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        order="2"
        order-lg="3"
        class="mr-0"
      >
          <v-text-field
            outlined
            label="Цена в салоне"
            placeholder="Цена автомобиля"
            v-model="kps[index].price"
            v-money="money"
            :disabled="archived"
            data-click-clear="clearPrice(index)"
          ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
        order="3"
        order-lg="3"
        data-class="mr-0 mb-8"
        data-align-self="end"
      >
        <v-btn v-if="index" text @click="removeFile(index)" color="error"><v-icon>mdi-minus</v-icon> Убрать предложение</v-btn>
      </v-col>
    </v-row>
      <v-text-field
        outlined
        label="Комментарий"
        placeholder="Есть авто, бронировать?"
        v-model="kps[index].comment"
        hide-details="auto"
        :disabled="archived"
      ></v-text-field>
      <v-divider class="mt-5 mb-7"></v-divider>
    </div>
    <v-btn :disabled="archived" text @click="addFile()" color="blue"><v-icon>mdi-plus</v-icon> Добавить предложение</v-btn>
  </v-form>
</template>

<script>
// import Component from 'vue-class-component'
// import { Mixins, Prop, Watch } from 'vue-property-decorator'
// import ValidationMixin from '@/mixins/ValidationMixin.vue'
// import FormMixin from '@/mixins/FormMixin.vue'
import { acceptedFileMimes } from '@/validation/rules'
/*
@Component({
  name: 'LeasObjectResponse'
})
*/

export default {
  props: ['dealerships', 'managerId', 'archived'],
  // mixins: [ValidationMixin, FormMixin],

  data() {
    return {
      form: {
        // comment: '',
        // price: 0,
        dealershipId: null,
        managerId: null
      },
      valid: true,
      kps: [{ file: null, price: 0, comment: '' }],

      carPriceMasked: '',

      money: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: ' руб.',
        precision: 0,
        masked: false /* doesn't work with directive */
      },

      fileRules: [
        // v => !v || v.every(el => acceptedFileMimes.indexOf(el.type) !== -1) || 'Загрузите файл нужного расширения: doc, docx, pdf, xls, xlsx'
        v => !v || acceptedFileMimes.indexOf(v.type) !== -1 || 'Загрузите файл нужного расширения: doc, docx, pdf, xls, xlsx'
      ]
    }
  },

  methods: {
    addFile() {
      this.kps.push({ file: null, price: 0, comment: '' })
    },

    removeFile(index) {
      this.kps.splice(index, 1)
    },

    clearPrice(index) {
      this.$nextTick(() => {
        if (index) this.kps.splice(index, 1)
        else this.kps[0].price = 0
      })
    },

    clearFile(index) {
      this.$nextTick(() => {
        if (index) this.kps.splice(index, 1)
      })
    }
  },

  watch: {
    dealerships: {
      handler: function() {
        if (this.dealerships.length === 1) {
          this.form.dealershipId = this.dealerships[0].id
        }
      },
      immediate: false,
      deep: false
    },

    managerId: {
      handler: function(val) {
        this.form.managerId = val
      },
      immediate: false,
      deep: false
    },

    carPriceMasked: {
      handler: function(val) {
        this.form.price = Number(val.slice(0, -4).replace(/\s+/g, ''))
      },
      immediate: false,
      deep: false
    }
  }
}
</script>
