<template>
  <div class="d-flex flex-column">
    <div class="page-block flex-column mb-8">
      <v-autocomplete
        v-model="managerId"
        :items="managers"
        item-text="fullName"
        item-value="id"
        :return-object="false"
        outlined
        label="Менеджер автосалона"
        placeholder="Выберите менеджер автосалона"
        :disabled="!!archived"
      ></v-autocomplete>
      <div class="d-flex flex-column flex-md-row">
        <leas-object-response
          ref="leasObjectResponseForm"
          :dealerships="dealerships"
          :manager-id="managerId"
          :archived="archived"
        ></leas-object-response>
        <div class="d-flex flex-column ml-0 ml-md-8 mt-8 mt-md-0">
          <v-btn
            outlined
            x-large
            color="error"
            class="mb-5"
            @click="sendLeasObjectResponse(true)"
            :disabled="!!archived"
          >
            Авто нет в наличии
          </v-btn>
          <v-btn
            color="secondary"
            x-large
            depressed
            @click="sendLeasObjectResponse()"
            :disabled="!!archived"
          >
            <span> Отправить </span>
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog
      max-width="500"
      v-model="noCarOfferDialog"
    >
      <NoCarOfferDialog
        @close="noCarOfferDialog = false"
        @submit="sendLeasObjectResponse(noCar)"
        @submitNoCarOffer="submitNoCarOffer"
      ></NoCarOfferDialog>
    </v-dialog>
  </div>
</template>

<script>
// import moment, { Moment } from 'moment'
import LeasObjectResponse from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/LeasObjectResponse.vue'
import { Http } from '@/apis/Http'
import TableMixin from '@/mixins/TableMixin.vue'
import { User } from '@/apis/User'
import _ from 'lodash'
import NoCarOfferDialog from '../../../RequestLeasObjects/RequestLeasObcjectsDetails/components/NoCarOfferDialog'

export default {

  props: ['archived'],
  mixins: [TableMixin],
  components: {
    LeasObjectResponse,
    NoCarOfferDialog
  },

  data() {
    return {
      managerId: null,
      dealerships: [],
      managers: [],
      noCarOfferDialog: false,
      noCar: false,
      noCarOffer: null
    }
  },

  computed: {
    requestId() {
      return this.$route.params.id
    }
  },

  watch: {
    managerId: {
      handler: function(id) { this.dealerships = _.find(this.managers, { id: id }).dealerships },
      immediate: false,
      deep: false
    }
  },

  created() {
    this.fetchDsManagers()
  },

  methods: {
    sendLeasObjectResponse(noCar = false) {
      this.noCar = noCar
      if (noCar && !this.noCarOfferDialog) {
        this.noCarOfferDialog = true
        return
      }

      this.noCarOfferDialog = false

      const formData = new FormData
      const leasObjectId = this.requestId

      if (!this.$refs.leasObjectResponseForm.$refs.form.validate()) return

      for (const kp of this.$refs.leasObjectResponseForm.kps) {
        if (kp.file) {
          formData.append('files[]', kp.file)
          formData.append('prices[]', kp.price.replace(/\D/g, ''))
          formData.append('comments[]', kp.comment)
        }
      }

      if (!formData.get('files[]') && !this.noCar) {
        this.showErrorSystemNotification('Для отправки коммерческого предложения прикрепите файл')
        return
      }

      // formData.append('comProposal', this.leasObjectResponseForm.form.file)
      formData.append('form', JSON.stringify({ leasObjectId, noCar, ...this.$refs.leasObjectResponseForm.form, noCarOffer: this.noCarOffer }))

      Http.post('/request-leas-objects/create-response', formData)
        .then(response => {
          if (response.data.success) {
            this.$router.push({ name: 'CarSearchRequests', params: { sended: '1' } })
          } else {
            this.showErrorSystemNotification(response.data.msg)
          }
        })
    },

    fetchDsManagers() {
      User.fetchDsManagers()
        .then(response => {
          this.managers = response.data.managers
        })
    },

    submitNoCarOffer(data) {
      this.noCarOffer = data
      this.sendLeasObjectResponse(true)
    }
  }
}
</script>
